import { FC } from "react";
import { Props } from "./Image.type";

const Image: FC<Props> = (props) => {
	return (
		<>
			{props.src && (
				<img src={props.src} alt={props.alt} className={props.className} />
			)}
		</>
	);
};

export { Image };
