import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { Steps } from "./stepsStore.type";
import * as helper from "./StepsStore.helper";

const InitialState = {
  currentStep: 0,
  completedSteps: [0],
};

const useStepsStore = create<Steps>()(
  devtools((set) => ({
    data: InitialState,
    actions: {
      changeStep: (step: number) =>
        set((store) => helper.changeStep(store, step)),
      nextStep: (auth: boolean) => {
        set((store) => helper.completeStep(store, auth));
      },
    },
  }))
);
export default useStepsStore;
