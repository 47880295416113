import { FC } from "react";
import Text from "@/Components/atoms/Text";
import TextWithIcon from "@/Components/molecules/TextWithIcon";
import { Props } from "./Offer.type";
import * as helper from "./Offer.helper";
import "./Offer.scss";

const Offer: FC<Props> = (props) => {
	return (
		<div className="offer">
			<TextWithIcon
				text={{ type: "h4", children: props.productName }}
				iconRight={{ src: props.icon, alt:props.icon }}
			/>
			<Text type="p" children={props.descriptionSm || helper.smMapping(props.sm)} />
			<Text type="p" children={props.descriptionTariffType || helper.rateMapping(props.tariffType)} />
		</div>
	);
};

export { Offer };
