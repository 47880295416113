import i18n from 'i18next';
import localeDataIt from '@/i18n/it.json';
import { initReactI18next } from 'react-i18next';
i18n
  .use(initReactI18next)
  .init({
    resources: {
      it: {
        translation: localeDataIt,
      },
    },
    lng: 'it', 
    fallbackLng: 'it',
    debug: true,
  });

export default i18n;
