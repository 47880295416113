import { Steps } from "./stepsStore.type";

const exitReturn = (store: Steps) => ({
	...store,
	data: { ...store.data },
});

const updateData = (store: Steps, updates: Partial<Steps["data"]>) => ({
	...store,
	data: {
		...store.data,
		...updates,
	},
});

const checkStep = (store: Steps, step: number) => {
return store.data.completedSteps.includes(step);

}
export const changeStep = (store: Steps, step: number) =>
	checkStep(store, step)
		? updateData(store, { currentStep: step })
		: exitReturn(store);

export const completeStep = (store: Steps, auth: boolean) => {
	const { completedSteps, currentStep } = store.data;
	const nextStep = currentStep + 1;

	if (auth) {
		const completed = checkStep(store, currentStep)
			? completedSteps
			: [...completedSteps, currentStep];

		return updateData(store, {
			completedSteps: completed,
			currentStep: nextStep,
		});
	} else {
		return exitReturn(store);
	}
};
