import {OtpValueType } from "./otpStore.type";

export const addNum = (
	number: number,
	pos: number,
	otp: OtpValueType
): OtpValueType => {
	otp[pos] = number;
	return otp;
};

export const removeNum = (pos: number, otp: OtpValueType): OtpValueType => {
	otp[pos] = null;
	return otp;
};

export const handleValidityOtp = (otp: OtpValueType): boolean => {
	if (!otp.includes(null)) {
		return true;
	}
	return false;
};
