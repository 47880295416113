import { FC } from "react";
import Text from "@/Components/atoms/Text";
import Image from "@/Components/atoms/Image";
import { Props } from "./TextWithIcon.type";
import "./TextWithIcon.scss";

const TextWithIcon: FC<Props> = (props) => {
	return (
		<div className={`text-with-icon ${props.className}`}>
			{/* icon left */}
			{props.iconLeft && (
				<Image
					className="left-icon"
					src={props.iconLeft.src}
					alt={props.iconLeft.alt}
				/>
			)}
			{/* without label */}
			{!props.label && (
				<Text type={props.text.type} className={props.text.className}>
					{props.text.children}
				</Text>
			)}
			{/* with label */}
			{props.label && (
				<div className="text-with-label">
					<Text type={props.text.type} className={props.text.className}>
						{props.text.children}
					</Text>
					<Text type="label" className={props.text.className}>
						{props.text.children}
					</Text>
				</div>
			)}
			{/* icon right */}
			{props.iconRight && (
				<Image
					className="right-icon"
					src={props.iconRight.src}
					alt={props.iconRight.alt}
				/>
			)}
		</div>
	);
};

export { TextWithIcon };
