import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import * as helper from './otpStore.helper';
import { Otp, OtpValueType } from './otpStore.type';


const InitialStatus = {
  name:'',
  message:'',
  status:0,
  details:{}
}
const useOtpStore = create<Otp>()(
  devtools(
    (set)=>({
      data: {
        isValid:false,
        otp:[null, null, null, null, null, null],
        otpStatus:null,
        otpMessage:"",
      },
      status:InitialStatus,
      actions:{
        writeOtp: (number:number, pos:number, otp:OtpValueType) => set(store => ({...store, data:{...store.data, otp:helper.addNum(number, pos, otp)} })),
        deleteOtp:(pos:number, otp:OtpValueType) => set(store=> ({...store, data:{...store.data, otp:helper.removeNum(pos, otp)}})),
        checkValidityOtp:(otp:OtpValueType)=>set(store=>({...store, data:{...store.data, isValid:helper.handleValidityOtp(otp)}})),
        checkResponse:(otpStatus:number, otpMessage:string)=>set(store=>({...store, status:{...store.status, status:otpStatus, message:otpMessage}})),
      }
    })
  )
);

export default useOtpStore
