import { FC } from "react";
import Text from "@/Components/atoms/Text";
import Button from "@/Components/atoms/Button";
import TextWithIcon from "@/Components/molecules/TextWithIcon";
import iconSuccess from "@/assets/success.png";
import iconError from "@/assets/error.png";
import { Props } from "./StatusCard.type";
import "./StatusCard.scss";
import { servizioClientiCta } from "@/utils/cta";

const StatusCard: FC<Props> = (props) => {
	return (
		<div className="status-card">
			<TextWithIcon
				text={{
					type: "h2",
					children: `${
						props.success
							? "Il contratto è stato concluso con successo."
							: "Ci dispiace, si è verificato un errore"
					}`,
				}}
				iconLeft={{ src: props.success ? iconSuccess : iconError }}
			/>
			<div className="info-status-message">
				{props.success && (
					<>
						<Text
							type="span"
							children="Riceverai un'e-mail di conferma all'indirizzo "
						/>
						<Text type="span" children={props.email || ""} />
						<Text type="span" children=" con i documenti contrattuali." />
					</>
				)}
				{!props.success && (
					<Text
						type="span"
						children="Si è verificato per un problema nell’inserimento dei dati."
					/>
				)}
			</div>
			{!props.success && (
				<Button
					onClick={servizioClientiCta}
					text="Contatta il Servizio Clienti"
				/>
			)}
		</div>
	);
};

export { StatusCard };
