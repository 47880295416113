import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Modals } from './modalStore.type';

const InitialState = {
  isOpen:false,
  title: ""
};

const useModalStore = create<Modals>()(
  devtools(set => ({
    data: InitialState,
    actions: {
      openModal: string => set(store =>  ({ ...store, data: { ...store.data, isOpen:true, title: string } })),
      closeModal: ()=> set(store=> ({...store, data:InitialState}))
    },
    
  })),
);
export default useModalStore;