import { Form, PrivacyFormStore } from "./formStore.type";

const updateStatus = (store: Form, updates: Partial<Form["status"]>) => ({
	...store,
	status: {
		...store.status,
		...updates,
	},
});

const updatePrivacyForm = (store:Form, updates: PrivacyFormStore) => ({
	...store,
	data:{
		...store.data,
		privacy:updates
	}
})


export const validateContractualForm = (store: Form, validate: boolean) => {
  return updateStatus(store, { contractual: validate });
};

export const setPrivacyFormValues = (store: Form, values:PrivacyFormStore) => {
	return updatePrivacyForm(store, values)
}
