import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {Icons} from './iconsStore.type';


const InitialState = {
  icons:[]
}

const useIconsStore = create<Icons>()(
  devtools((set)=>({
    data:InitialState,
    actions:{
      setIcons: (icons)=>set((store)=> ({...store, data:{icons:icons}}))
    }
  }))
);

export default useIconsStore;