import { FC } from "react";
import Text from "@/Components/atoms/Text";
import TextWithIcon from "@/Components/molecules/TextWithIcon";
import { Props } from "./SimpleCard.type";
import "./SimpleCard.scss";
const SimpleCard: FC<Props> = (props) => {
  return (
    <div className="simple-card">
      <TextWithIcon
        text={{ type: "h2", children: props.title || "" }}
        iconLeft={{ src: props.img }}
      />
      <Text type="p" children={props.subtitle || ""} />

      {props.secondSubtitle ? (
        <p className="second-subtitle">{props.secondSubtitle}</p>
      ) : null}
    </div>
  );
};

export { SimpleCard };
