import { FC, Suspense, lazy, useRef } from "react";
import { RouterProvider, createBrowserRouter, Outlet } from "react-router-dom";
import Loader from "./Components/atoms/Loader";
import Header from "./Components/sections/Header";
import Footer from "./Components/sections/Footer";
import ErrorSection from "./Components/sections/ErrorSection";
import logo from "@/assets/logo.svg";
import "./App.scss";

const App: FC = () => {
	const appRef = useRef<HTMLDivElement>(null);
	const SignPage = lazy(() => import("./Pages/SignPage"));
	const ThankYouPage = lazy(() => import("./Pages/ThankYouPage"));
	const router = createBrowserRouter([
		{
			path: "/",
			element: (
				<>
					<Header logo={logo} />
					<Outlet />
					<Footer />
				</>
			),
			children: [
				{
					path: "/",
					element: (
						<Suspense fallback={<Loader />}>
							<SignPage appRef={appRef} />
						</Suspense>
					),
				},
				{
					path: "thank-you",
					element: (
						<Suspense fallback={<Loader />}>
							<ThankYouPage appRef={appRef} />
						</Suspense>
					),
				},
				{
					path: "*", // Route di fallback per gestire tutte le altre route
					element: (
						<Suspense fallback={<Loader />}>
							<ErrorSection status={400} message="Page not found" />
						</Suspense>
					),
				},
			],
		},
	]);
	return (
		<>
			<div className="App" ref={appRef}>
				<RouterProvider router={router} />
			</div>
		</>
	);
};

export default App;
