import { FC } from "react";
import Text from "@/Components/atoms/Text";
interface Props {
	type: string;
	price: number;
}
import "./Price.scss";
const Price: FC<Props> = (props) => {
	return (
		<div className={`price ${props.type}`}>
			<Text type="span" children={`${props.price}`} />
			<Text type="span" children=" €/mese" />
		</div>
	);
};

export { Price };
