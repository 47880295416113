import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { RequestStoreData } from './requestStore.type';
import {InitialState , InitialStatus} from './initialState';

const useRequestStore = create<RequestStoreData>()(
  devtools(
    (set) => ({
      data: InitialState,
      requestStatus: InitialStatus,
      token: null,
      actions: {
        getDataRequest: request => set(store => ({...store, data:request})),
        setRequestStatus: status => set(store =>({...store, requestStatus:status})),
        setToken: token => set(store => ({...store, token})),
      }
    })
  )
);

export default useRequestStore;