import { ReactNode } from "react";

export const parseStrongText = (txt: string): ReactNode => {
	if(!txt) return null;
	const strongTextRegex = /<strong>(.*?)<\/strong>/g;
	const txtParts = txt.split(strongTextRegex);

	return txtParts.map((part, index) => {
		if (index % 2 === 1) {
			return (
				<span key={index} style={{ fontWeight: 700 }}>
					{part}
				</span>
			);
		}
		return part;
	});
};

export const parseUnderlineText = (txt: string): ReactNode => {
	if(!txt) return null;

	if (txt !== "" && txt !== undefined) {
		if (txt.includes("<u>") && txt.includes("</u>")) {
			const underlineTextRegex = /<u>(.*?)<\/u>/;
			const txtParts = txt.split(underlineTextRegex);

			return txtParts.map((part, index) => {
				if (index % 2 === 1) {
					return (
						<span key={index} style={{ textDecoration: "underline" }}>
							{part}
						</span>
					);
				}
				return part;
			});
		}
	}
	return txt;
};
