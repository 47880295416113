import { ErrorMessage } from "./ErrorSection.type";
import invalidSearch from '@/assets/invalid-search.svg';
import notFound from '@/assets/not-found.svg';
import generalError from '@/assets/problem-error.svg'
export const defineTitle = (status:number, message:string):string =>{
  if(status===500 || status===404 || (status===400 && message === ErrorMessage.SignCantBeSign)) {
    return 'Ci scusiamo, si è verificato un problema tecnico.'
  } else if ((status===400 && message === ErrorMessage.SignNotActive) || message===ErrorMessage.ReqExpired) {
    return 'Ops, il link non è più valido.'
  } else if ((status===400 && message === ErrorMessage.NotFound) || status===401)  {
    return 'Ops, pagina non trovata.'
  }
  return ''
}
export const defineTexts = (status:number, message:string):string => {
  if(status===500 || status===404 || (status===400 && message === ErrorMessage.SignCantBeSign) ) {
    const text = 'Al momento non è possibile accedere alla sottoscrizione dell’offerta a causa di un problema temporaneo nei nostri sistemi. Ti invitiamo a riprovare tra qualche minuto.'
    return text
  } else if ((status===400 && message === ErrorMessage.SignNotActive) || message===ErrorMessage.ReqExpired) {
    const text = "Sembra che questo link sia scaduto e non sia più attivo per la sottoscrizione dell’offerta. Contatta un nostro consulente per ricevere un nuovo link e tutte le informazioni necessarie."
    return text
  } else if ((status===400 && message === ErrorMessage.NotFound) || status===401) {
    return 'Sembra che il link non conduca a una pagina valida. Controlla di aver inserito correttamente l’indirizzo oppure contatta un nostro consulente per ricevere assistenza e continuare con la tua sottoscrizione.'
  }
  return '';
}

export const setErrorCardImg = (status:number, message:string):string => {
  if(status===500 || status===404 || (status===400 && message === ErrorMessage.SignCantBeSign)) {
    return generalError
  } else if ((status===400 && message === ErrorMessage.SignNotActive) || message===ErrorMessage.ReqExpired ) {
    return invalidSearch
  } else if ((status===400 && message === ErrorMessage.NotFound) || status===401)  {
    return notFound
  }
  return ''
}