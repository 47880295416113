import { FC } from "react";
import Text from "@/Components/atoms/Text";

interface Props {
	texts: string[];
	types: string[];
}
const Texts: FC<Props> = (props) => {
	return (
		<>
			{props.texts.map((text: string, index: number) => {
				return <Text key={index} type={props.types[index]} children={text} />;
			})}
		</>
	);
};

export { Texts };
