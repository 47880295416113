export const InitialState = {
  id: 0,
  message_id: "",
  status: "",
  resend_email: false,
  client_email: "",
  agent_email: "",
  fiscal_code: "",
  tax_code: "",
  phone: "",
  client_id: "",
  request_date: "",
  activation_date: "",
  utilities: [
    {
      id: "",
      name: ""
    }
  ],
  blocks: [
    {
      headline_title: "",
      headline_icon: "",
      items: [
        {
          key: "",
          value: ""
        }
      ]
    }
  ],
  services: [
    {
      name: "",
      icon: "",
      price: 0,
      discounted_price: 0,
      subservices: [
        {
          icon: "",
          name: ""
        }
      ]
    }
  ],
  product_id: "",
  token: "",
  signature_id: "",
  process: "",
  otp_code: null,
  otp_date: null,
  createdAt: "",
  updatedAt: "",
  publishedAt: null,
  consent_1: false,
  consent_2: false,
  consent_3: false,
  product: {
    id: "",
    name: "",
    start_date: "",
    end_date: "",
    icon: "",
    description: "",
    tariff_type: "",
    creation_date: "",
    description_tariff_type:"",
    description_sm:"",
    documents: [
      {
        name: "",
        url: ""
      }
    ],
    privacy_document: [
      {
        name: "",
        url: ""
      }
    ],
    blocks: [
      {
        headline_title: "",
        items: [
          {
            key: "",
            value: "",
            unit: "",
            apex: ""
          }
        ]
      }
    ]
  }
};

export const InitialStatus = {
  name:'',
  message:'Loading',
  status:200,
  details:{}
}
