export type TextType =
	| "h1"
	| "h2"
	| "h3"
	| "h4"
	| "h5"
	| "h6"
	| "span"
	| "p"
	| "label"

export enum HtmlType {
	h1 = "h1",
	h2 = "h2",
	h3 = "h3",
	h4 = "h4",
	h5 = "h5",
	h6 = "h6",
	span = "span",
	p = "p",
	label = "label",
}

export interface Props {
	type?: TextType | string;
	children: string;
	className?: string;
	onClick?: ()=>void
}