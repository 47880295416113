import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import * as helper from './formStore.helper';
import { Form, PrivacyFormStore } from './formStore.type';

const InitialStatePrivacy = {
  consent_1: false,
  consent_2: false,
  consent_3: false,
};

const formsStatus = {
  contractual: false,
};

const formsData = {
  privacy: InitialStatePrivacy,
};

const useFormStore = create<Form>()(
  devtools((set) => ({
    data: formsData,
    status: formsStatus,
    actions: {
      validateContractualForm: (validate: boolean) => set((store) => helper.validateContractualForm(store, validate)),
      setPrivacyFormValues: (values: PrivacyFormStore) => set((store) => helper.setPrivacyFormValues(store, values))
    },
  }))
);

export default useFormStore;
