import { FC } from "react";
import "./Loader.scss";
const Loader: FC = () => {
	return (
		<div id="loader-wrapper">
			<div id="loader"></div>
		</div>
	);
};

export { Loader };
