import { FC } from "react";
import { Service } from "@/types/signatureRequest.type";
import Image from "@/Components/atoms/Image";
import Text from "@/Components/atoms/Text";
import Texts from "@/Components/molecules/Texts";
import "./ServiceCard.scss";
import Price from "@/Components/molecules/Price";
interface Props {
	className?: string;
	service: Service;
}
const ServiceCard: FC<Props> = (props) => {
	return (
		<div className={`service-card ${props.className}`}>
			<Image src={props.service.icon} alt="icon" />
			<div className="service-info">
				<div className="service-name">
					{props.service.subservices && (
						<Text type="h4" children={props.service.name} />
					)}
					{!props.service.subservices && (
						<Texts
							types={["h5", "p"]}
							texts={[props.service.name, props.service.description]}
						/>
					)}
				</div>
				<div className="prices">
					{props.service.discounted_price && (
						<Price type="discounted" price={props.service.discounted_price} />
					)}
					{props.service.discounted_price && (
						<Price type="full" price={props.service.price} />
					)}
					{!props.service.discounted_price && (
						<Price type="discounted" price={props.service.price} />
					)}
				</div>
			</div>
		</div>
	);
};

export { ServiceCard };
