import { FC } from "react";
import Card from "@/Components/organisms/Card";
import * as helper from "./ErrorSection.helper";
import { Props } from "./ErrorSection.type";
import "./ErrorSection.scss";

const ErrorSection: FC<Props> = (props) => {
	return (
		<div className="error-section">
			<div className="logo"></div>
			<Card
				type="error"
				titleCard={helper.defineTitle(props.status, props.message)}
				subtitleCard={helper.defineTexts(props.status, props.message)}
				errorStatus={props.status}
				imgCard={helper.setErrorCardImg(props.status, props.message)}
			/>
		</div>
	);
};

export { ErrorSection };
