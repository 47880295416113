
export type Button = "icon" | "line" | "default";

export enum ButtonType {
  I = 'icon',
  L = 'line',
  D = 'default'
}

export interface Props {
  text?: string;
  icon?:string;
  type?:Button;
  active?: boolean;
  primary?: boolean;
  className?:string;
  onClick: () => void;
}