import { FC, useState, useEffect } from "react";
import Store from "@/Store";
import Image from "@/Components/atoms/Image";
import { Props } from "./Header.type";
import "./Header.scss";
const Header: FC<Props> = (props) => {
	const [headerClass, setHeaderClass] = useState<string>("header");
	const requestStatus = Store.useRequestStore((store) => store.requestStatus);

	useEffect(() => {
		if (
			requestStatus.status === 500 ||
			requestStatus.status === 400 ||
			requestStatus.status === 401 ||
			requestStatus.status === 404
		) {
			setHeaderClass("header-error-page");
		} else {
			setHeaderClass("header");
		}
	}, [requestStatus]);
	return (
		<header className={headerClass}>
			<div className="header-ctx">
				<Image src={props.logo} alt="logo" />
			</div>
		</header>
	);
};

export { Header };
