export enum RateMap {
	D="Tariffa Domestica",
	B="Tariffa Business",
	C="Tariffa Condominio"
}

export enum SmMap {
	E="Offerta di Energia Elettrica",
	G="Offerta di Gas Naturale",
	F="Offerta per il Servizio Internet"
}

export enum RateKey {
	D="D",
	B="B",
	C="C"
}

export enum SmKey {
	E="E",
	G="G",
	F="F"
}

export interface Props {
	productName: string;
	icon: string;
	sm:string;
	tariffType:string;
	descriptionSm:string;
  descriptionTariffType:string;
}