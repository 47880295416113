import { FC } from "react";
import { Props } from "./HyperLink.type";
const HyperLink: FC<Props> = (props) => {
	return (
		<span>
			<a href={props.link} target="_blank" rel="noopener noreferrer">
				{props.text}
			</a>
		</span>
	);
};

export { HyperLink };
