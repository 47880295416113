import { FC } from "react";
import Offer from "./Offer";
import StatusCard from "./StatusCard";
import SimpleCard from "./SimpleCard";
import ErrorCard from "./ErrorCard";
import { Props } from "./Card.type";
import ServiceCard from "./ServiceCard";
const Card: FC<Props> = (props) => {
  return (
    <>
      {props.type === "offer" && (
        <Offer
          icon={props.imgCard}
          productName={props.productName || ""}
          sm={props.sm || ""}
          tariffType={props.tariffType || ""}
          descriptionTariffType={props.descriptionTariffType || ""}
          descriptionSm={props.descriptionSm || ""}
        />
      )}
      {props.type === "status" && (
        <StatusCard email={props.email} success={props.success} />
      )}
      {props.type === "simple" && (
        <SimpleCard
          title={props.titleCard}
          subtitle={props.subtitleCard}
          secondSubtitle={props.secondSubtitle}
          img={props.imgCard}
        />
      )}
      {props.type === "service" && props.service && (
        <ServiceCard className={props.className} service={props.service} />
      )}
      {props.type === "error" && (
        <ErrorCard
          title={props.titleCard || ""}
          sutitle={props.subtitleCard || ""}
          image={props.imgCard || ""}
          errorStatus={props.errorStatus}
        />
      )}
    </>
  );
};

export { Card };
