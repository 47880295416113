import { FC } from "react";
import { Props, ButtonType } from "./Button.type";
import "./Button.scss";
const Button: FC<Props> = (props) => {
	const isActive = props.active !== undefined ? props.active : true;
	const isPrimary = props.primary !== undefined ? props.primary : true;

	const handleClick = () => {
		if (isActive) {
			props.onClick();
		}
	};

	switch (props.type) {
		case ButtonType.I:
			return (
				<button
					className={`icon-button ${props.className}`}
				>
					<img onClick={handleClick} src={props.icon} alt="icon-button" />
				</button>
			);
		case ButtonType.L:
			return (
				<button
					className={`line-button ${props.className} ${
						isActive ? "active" : "disable"
					}`}
				>
					<span onClick={handleClick}>{props.text}</span>
				</button>
			);
		default:
			return (
				<button
					onClick={handleClick}
					className={`default-button ${props.className} ${
						isActive ? "active" : "disable"
					} ${isPrimary ? "primary" : "secondary"}`}
				>
					{props.icon && <img src={props.icon} alt="icon-button" />}
					<span>{props.text}</span>
				</button>
			);
	}
};

export default Button;
