import { RateMap,  SmMap, RateKey, SmKey} from "./Offer.type";


export const rateMapping = (value:string):string=> {
  switch (value) {
    case RateKey.D:
      return RateMap.D;
    case RateKey.B:
      return RateMap.B;
    case RateKey.C:
      return RateMap.C;
    default:
      return ''
  }
};

export const smMapping = (value:string):string=> {
  switch (value) {
    case SmKey.E:
      return SmMap.E;
    case SmKey.G:
      return SmMap.G;
    case SmKey.F:
      return SmMap.F;
    default:
      return ''
  }
};