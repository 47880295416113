import { FC } from "react";
import * as helper from "./Text.helper";
import { HtmlType, Props } from "./Text.type";

export const Text: FC<Props> = (props) => {
	switch (props.type) {
		case HtmlType.h1:
			return (
				<h1 className={props.className}>
					{helper.parseUnderlineText(props.children)}
				</h1>
			);
		case HtmlType.h2:
			return (
				<h2 className={props.className}>
					{helper.parseUnderlineText(props.children)}
				</h2>
			);
		case HtmlType.h3:
			return (
				<h3 className={props.className}>
					{helper.parseUnderlineText(props.children)}
				</h3>
			);
		case HtmlType.h4:
			return (
				<h4 className={props.className}>
					{helper.parseUnderlineText(props.children)}
				</h4>
			);
		case HtmlType.h5:
			return (
				<h5 className={props.className}>
					{helper.parseUnderlineText(props.children)}
				</h5>
			);
		case HtmlType.h6:
			return (
				<h6 className={props.className}>
					{helper.parseUnderlineText(props.children)}
				</h6>
			);
		case HtmlType.span:
			return (
				<span onClick={props.onClick} className={props.className}>
					{helper.parseUnderlineText(props.children)}
				</span>
			);
		case HtmlType.p:
			return (
				<p className={props.className}>
					{helper.parseUnderlineText(props.children)}
				</p>
			);
		case HtmlType.label:
			return (
				<label className={props.className}>
					{helper.parseUnderlineText(props.children)}
				</label>
			);
		default:
			return <div>{helper.parseUnderlineText(props.children)}</div>;
	}
};
