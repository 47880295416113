import { FC } from "react";
import Image from "@/Components/atoms/Image";
import Text from "@/Components/atoms/Text";
import Button from "@/Components/atoms/Button";
import Texts from "@/Components/molecules/Texts";
import { Props } from "./ErrorCard.type";
import "./ErrorCard.scss";
import { servizioClientiCta } from "@/utils/cta";

const ErrorCard: FC<Props> = (props) => {
	return (
		<div className="error-card">
			<Image src={props.image} />
			<Texts types={["h3", "p"]} texts={[props.title, props.sutitle]} />
			{props.errorStatus === 500 && (
				<Text type="p">
					Per qualsiasi necessità, un nostro consulente è a tua disposizione.
				</Text>
			)}
			<Button
				onClick={servizioClientiCta}
				text="Contatta il Servizio Clienti"
			/>
		</div>
	);
};

export { ErrorCard };
