export enum ErrorMessage {
  GeneralError= "Connection Refused",
  SignNotActive = "Signature request is not active",
  NotFound = "Page not found",
  ReqExpired = "Signature request has expired",
  SignCantBeSign = "Signature request can not be signed at this moment"
}

export interface Props {
  status:number;
  message:string
}