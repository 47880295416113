import { FC, useState, useEffect } from "react";
import Store from "@/Store";
import { useTranslation } from "react-i18next";
import HyperLink from "@/Components/atoms/HyperLink";
import { Link, Props } from "./Footer.type";
import "./Footer.scss";
const Footer: FC<Props> = (props) => {
	const currentStep = Store.useStepsStore((store) => store.data.currentStep);
	const requestStatus = Store.useRequestStore(
		(store) => store.requestStatus.status
	);
	const { t } = useTranslation();
	const footerLinks = t("FOOTER", { returnObjects: true }) as Link[];

	const [marginBottom, setMarginBottom]= useState<number>(0);

	useEffect(()=>{
		if(props.heightMobile){
			setMarginBottom(props.heightMobile - 20)
		}
	}, [props.heightMobile])
	return (
		<>
			{requestStatus !== 500 &&
				requestStatus !== 400 &&
				requestStatus !== 401 &&
				requestStatus !== 404 && (
					<footer style={{marginBottom:marginBottom}} className={currentStep === 2 ? "remove-margin" : ""}>
						<div className="footer-content">
							{footerLinks.map((link: Link, index: number) => {
								return (
									<HyperLink key={index} link={link.LINK} text={link.TEXT} />
								);
							})}
						</div>
					</footer>
				)}
		</>
	);
};

export { Footer };
